import React, { createContext, useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import ReactGA from "react-ga";
import content from '../constants/content.json'

export const AppContext = createContext({});

const AppProvider = ({ children }) => {

  const location = useLocation();
  const history = useHistory();

  const ua = navigator.userAgent || navigator.vendor || window.opera;
  // var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

  const [darkMode, setDarkMode] = useState(false);
  const [slowMode, setSlowMode] = useState(false);
  
  const [isMobile, setIsMobile] = useState(undefined);

  const _screenSize = useRef({width: window.innerWidth, height: window.innerHeight})
  const [screenSize, setScreenSize] = useState({width: window.innerWidth, height: window.innerHeight});

  const [menuOpen, setMenuOpen] = useState(undefined);
  const [activeVideo, setActiveVideo] = useState();

  const browser = useRef({
    instagram: ua.indexOf('Instagram') > -1,
    ios: /iPad|iPhone|iPod/.test(ua) && !window.MSStream
  });

  const onResize = (e) => {
    setScreenSize({width: window.innerWidth, height: window.innerHeight});
  }
  
  const movies = useRef(content.fields.videos.map(v => {
    return {
      aspectRatio: v.fields.aspectRatio,
      coverImage: v.fields.coverImage?.fields?.file?.url,
      id: v.fields.id,
      roles: v.fields.roles,
      title: v.fields.title,
      vimeoId: v.fields.vimeoId
    }
  }))

  const info = {
    title: content.fields.title,
    about: content.fields.about,
    email: content.fields.email,
    instagram: content.fields.instagram,
  }

  const toggleMenu = () => {
    ReactGA.event({
      category: "about page",
      action: menuOpen ? 'close' : 'open'
    });
    setMenuOpen(!menuOpen)
  }
  
  useEffect(() => {
    const isLanding = activeVideo === movies.current[0].id && location.pathname === '/'

    if (activeVideo && `/${activeVideo}` !== location.pathname && !isLanding) {
      history.push(`/${activeVideo}`);
    }
  }, [activeVideo]) // eslint-disable-line

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('is-dark-mode')
    } else {
      document.body.classList.remove('is-dark-mode')
    }
  }, [darkMode])

  useEffect(() => {
    _screenSize.current = screenSize
  }, [screenSize])

  const selectVideo = (index) => {
    setActiveVideo(index)
    if (menuOpen) setMenuOpen(false)
    ReactGA.event({
      category: "select video",
      action: index
    });
  }

  useEffect(() => {
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    window.addEventListener('resize', onResize)

    if (browser.current.instagram && browser.current.ios) {
      setInterval(() => {
        if (_screenSize.current.width !== window.innerWidth) {
          setScreenSize({width: window.innerWidth, height: window.innerHeight});
        }
      }, 2000)
    }

    const movieMatch = movies.current.find(m => `/${m.id}` === location.pathname);
    setActiveVideo(movieMatch?.id || movies.current[0].id);
  }, []) // eslint-disable-line
  
  return (
    <AppContext.Provider value={{browser, movies, info, isMobile, screenSize, darkMode, setDarkMode, slowMode, setSlowMode, menuOpen, toggleMenu, activeVideo, selectVideo}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;