import styles from './spinner.module.scss';

function Spinner() {
  return (
    <div className={styles.ldsSpinner}>
      { [...Array(12)].map((e, i) => (
        <div key={`spinner=${i}`}></div>
      ))}
    </div>
  );
}

export default Spinner;
