// import { gsap } from "gsap/all";
import { useEffect, useRef } from "react";
import Blob from './Blob';
import styles from './blobs.module.scss';

function Blobs() {

  const requestRef = useRef();
  const previousTimeRef = useRef();
  const canvas = useRef();
  const ctx = useRef();
  const totalBlobs = 10;
  const blobs = useRef([]);

  const drawBlobs = (canvas) => {

    blobs.current = [];

    for (let i = 0; i < 1000; i++) {

      const radiusMax = window.innerWidth < 768 ? 150 : 250

      const radius = Math.random() * radiusMax + 100
      const relativeRadius = {
        x: radius / window.innerWidth,
        y: radius / window.innerHeight,
      }
      
      const position = {
        x: Math.random() * (1 - (2 * relativeRadius.x)) + relativeRadius.x,
        y: Math.random() * (1 - (2 * relativeRadius.y)) + relativeRadius.y,
      }

      let canFit = true;
      for (let _blob of blobs.current) {

        var a = (position.x * window.innerWidth) - (_blob.position.x * window.innerWidth);
        var b = (position.y * window.innerHeight) - (_blob.position.y * window.innerHeight);
        var distance = Math.sqrt( a*a + b*b );

        if (distance < (radius + _blob.radius)) {
          canFit = false;
          break;
        }
      }

      if (canFit) {
        const blob = new Blob({ canvas: canvas, position, radius, index: i });
  
        blobs.current.push({
          blob,
          radius,
          position,
          hover: false
        })

        if (blobs.current.length >= totalBlobs) {
          break;
        }
      }
    }

    for (let blob of blobs.current) {
      blob.blob.init();
      blob.blob.tick(0);
    }
  }

  const redrawTimeout = useRef();

  useEffect(() => {

    const _canvas = canvas.current
    ctx.current = _canvas.getContext('2d');
    // const blob = new Blob({ _canvas });
    // drawBlobs(_canvas)

    _canvas.setAttribute('touch-action', 'none');

    let resize = function() {
      _canvas.width = window.innerWidth;
      _canvas.height = window.innerHeight;

      if (redrawTimeout.current) {
        clearTimeout(redrawTimeout.current);
        redrawTimeout.current = undefined
      }
      
      redrawTimeout.current = setTimeout(() => {
        clearTimeout(redrawTimeout.current);
        redrawTimeout.current = undefined
        drawBlobs(_canvas);
      }, 500);
    }

    _canvas.width = window.innerWidth;
    _canvas.height = window.innerHeight;
    drawBlobs(_canvas);

    window.addEventListener('resize', resize)
  
    let oldMousePoint = { x: 0, y: 0};
    // let hover = false;

    let mouseMove = function(e) {

      for (let b of blobs.current) {
        const blob = b.blob
      
        let pos = blob.center;
        let diff = { x: e.clientX - pos.x, y: e.clientY - pos.y };
        let dist = Math.sqrt((diff.x * diff.x) + (diff.y * diff.y));
        let angle = null;
        
        blob.mousePos = { x: pos.x - e.clientX, y: pos.y - e.clientY };
        
        if(dist < blob.radius && b.hover === false) {
          let vector = { x: e.clientX - pos.x, y: e.clientY - pos.y };
          angle = Math.atan2(vector.y, vector.x);
          b.hover = true;
          // blob.color = '#77FF00';
        } else if(dist > blob.radius && b.hover === true){ 
          let vector = { x: e.clientX - pos.x, y: e.clientY - pos.y };
          angle = Math.atan2(vector.y, vector.x);
          b.hover = false;
          blob.color = null;
        }
        
        if(typeof angle == 'number') {
          
          let nearestPoint = null;
          let distanceFromPoint = 100;
          
          blob.points.forEach((point)=> {
            if(Math.abs(angle - point.azimuth) < distanceFromPoint) {
              nearestPoint = point;
              distanceFromPoint = Math.abs(angle - point.azimuth);
            }
            
          });
          
          if(nearestPoint) {
            let strength = { x: oldMousePoint.x - e.clientX, y: oldMousePoint.y - e.clientY };
            strength = Math.sqrt((strength.x * strength.x) + (strength.y * strength.y)) * 0.5;
            if(strength > 100) strength = 100;
            nearestPoint.acceleration = strength / 100 * (b.hover ? -1 : 1);
          }
        }
      }
        
      oldMousePoint.x = e.clientX;
      oldMousePoint.y = e.clientY;
    }

    // window.addEventListener('mousemove', mouseMove);
    window.addEventListener('pointermove', mouseMove);

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);


  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      // const deltaTime = time - previousTimeRef.current;

      ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);

      for (let blob of blobs.current) {
        blob.blob.tick(time);
      }
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  

  return (
    <canvas className={styles.blobs} ref={canvas}></canvas>
  );
}

export default Blobs;
