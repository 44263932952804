import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import AppProvider from './context/appContext';
import Border from './components/border'
import Blobs from './components/blobs'
import InfoButton from './components/InfoButton'
import InfoPage from './components/InfoPage'
import styles from  './App.module.scss';

function App() {

  ReactGA.initialize('UA-151203088-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className={styles.app}>
      
        <Router>
          <Switch>
            <Route path={["/", "/:id"]} children={
              <AppProvider>
                <Blobs />
                <Border />
                <InfoButton />
                <InfoPage />
                </AppProvider>
            } />
          </Switch>
        </Router>
      
    </div>
  );
}

export default App;
