// import { gsap } from "gsap/all";
// gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin); 
import { useEffect, useRef, useState, Fragment, useContext } from "react";
import classNames from "classnames";
import VideoFloater from '../VideoFloater'
import styles from './border.module.scss';
import { AppContext } from "../../context/appContext";
import useFontFaceObserver from 'use-font-face-observer';

function Border() {
  const { screenSize, movies, slowMode, setSlowMode, darkMode, activeVideo, selectVideo } = useContext(AppContext);
  
  const [count, setCount] = useState(0)
  const [linkList, setLinkList] = useState()
  
  const hoverIndex = useRef(null)
  const _slowMode = useRef(false)

  const requestRef = useRef();
  const previousTimeRef = useRef();
  
  const turnSpeed = useRef(0.05)
  const turnSpeedTarget = useRef(0.05)
  
  const links = useRef();
  const spacer = useRef();
  const spacerWidth = useRef();

  const totalLinksLength = useRef();
  const dupesLength = useRef();

  // const width = window.innerWidth;
  // const height = window.innerHeight;

  const padding = useRef(window.innerWidth < 768 ? 10 : 20)
  // const padding.current = width < 768 ? 10 : 20

  const namesPadding = 10

  const isFontListLoaded = useFontFaceObserver([
    {
      family: `Cooper Std`,
      style: `italic`,
      weight: `900`
    },
  ]);

  useEffect(() => {
    if (isFontListLoaded) {
      init();
      requestRef.current = requestAnimationFrame(animate);
      return () => cancelAnimationFrame(requestRef.current);
    }
  }, [isFontListLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFontListLoaded) init();
  }, [screenSize]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    _slowMode.current = slowMode
  }, [slowMode]) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    setTimeout(() => {
      init();
    }, 3000);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      const lerpfactor = hoverIndex.current !== null ? 0.1 : _slowMode.current ? 0.005 : 0.1
    
      turnSpeed.current = turnSpeed.current + ((turnSpeedTarget.current - turnSpeed.current) * lerpfactor)
      setCount(prevCount => ((prevCount + deltaTime * turnSpeed.current) % totalLinksLength.current));
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  
  const init = () => {
    padding.current = window.innerWidth < 768 ? 10 : 20
    const _links = links.current.querySelectorAll('span')
    
    let offset = 0;
    let _linkSizes = []

    spacerWidth.current = spacer.current.getBoundingClientRect().width
    
    for (let i = 0; i < movies.current.length; i++) {
      let movie = movies.current[i];
      let link = _links[i];
      let _width = link.getBoundingClientRect().width

      _linkSizes.push({
        ...movie,
        width: _width,
        offset: offset,
        active: false
      })

      offset += _width + spacerWidth.current + (namesPadding * 2)
    }

    const totalLength = ((window.innerWidth - 80) * 2) + ((window.innerWidth - 80) * 2)

    totalLinksLength.current = offset
    dupesLength.current = Math.ceil(totalLength / totalLinksLength.current) + 1
    setLinkList(_linkSizes)
  };

  const mouseOverLink = (index) => {
    hoverIndex.current = index;
    setSlowMode(true)
  }

  const mouseOutLink = () => {
    if (!darkMode) setSlowMode(false)
    hoverIndex.current = null;
  }

  useEffect(() => {
    if (slowMode) {
      turnSpeedTarget.current = 0.01;
    } else {
      turnSpeedTarget.current = 0.05;
    }
  }, [slowMode])

  return (
    <div className={styles.border}>
      <div ref={links} className={styles.text}>
        {movies.current.map(m => (
          <Fragment key={`${m.title}-width`}>
            <span>{m.title}</span><br/>
          </Fragment>
        ))}
        <span ref={spacer}>{'//'}</span><br />
      </div>

      {isFontListLoaded && linkList && (
        <svg width="100%" height="100%" preserveAspectRatio="none" className={classNames([styles.svg, darkMode && styles.darkMode])}>
          <rect className={styles.borderBG} x="0" y="0" width={window.innerWidth} height={padding.current * 3} />
          <rect className={styles.borderBG} x="0" y={window.innerHeight - padding.current * 3} width={window.innerWidth} height={padding.current * 3} />
          <rect className={styles.borderBG} x="0" y="0" width={padding.current * 3} height={window.innerHeight} />
          <rect className={styles.borderBG} x={window.innerWidth - padding.current * 3} y="0" width={padding.current * 3} height={window.innerHeight} />
          <path id="master" d={`M ${padding.current * 2} ${padding.current * 2} H ${window.innerWidth - (padding.current * 2)} V ${window.innerHeight - (padding.current * 2)} H ${padding.current * 2} V ${padding.current * 3}`} fill="transparent" />
          <path id="master" d={`M ${padding.current * 3} ${padding.current * 3} H ${window.innerWidth - (padding.current * 3)} V ${window.innerHeight - (padding.current * 3)} H ${padding.current * 3} Z`} fill="transparent" stroke="black" strokeWidth="8" />

            { [...Array(dupesLength.current)].map((e, i) => (
              linkList.map((link, index) => (
                <Fragment key={`text-row-${i}-word-${index}`}>
                  <text className={classNames([styles.link, activeVideo === link.id && styles.active])} fill="#000" textLength={link.width}>
                    <textPath onClick={() => { selectVideo(link.id) }} onMouseOver={(e) => { mouseOverLink(index) }} onMouseOut={mouseOutLink} href="#master" id="masterTextPath" textLength={link.width} startOffset={(totalLinksLength.current * i) + link.offset - count}>{link.title}</textPath>
                  </text>
                  <text fill="#000" textLength={spacerWidth.current}>
                    <textPath href="#master" id="masterTextPath" textLength={spacerWidth.current} startOffset={(totalLinksLength.current * i) + (link.offset + link.width + namesPadding) - count}>{'//'}</textPath>
                  </text>
                </Fragment>
              ))
            ))}
        </svg>
      )}

      {linkList && (
        linkList.map((link, index) => (
          <VideoFloater 
            key={`floater-${index}`}
            active={activeVideo === link.id} 
            hover={hoverIndex.current === index} 
            aspectRatio={link.aspectRatio} 
            vimeoId={link.vimeoId} 
            cover={link.coverImage} 
            padding={padding.current}
          />
        ))
      )}
    </div>
  );
}

export default Border;
