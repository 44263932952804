import { useContext } from "react";
import ReactGA from "react-ga";
import { AppContext } from "../../context/appContext";
import classNames from "classnames";
import styles from './socialbuttons.module.scss';
import { ReactComponent as EmailIcon } from '../../assets/icons/email-outline.svg';
import { ReactComponent as IntsaIcon } from '../../assets/icons/instagram.svg';

function InfoButton() {
  const { info, menuOpen } = useContext(AppContext);

  if (!menuOpen) return null

  const track = (label) => {
    ReactGA.event({
      category: "social link",
      action: label
    });
  }

  return (
    <div className={styles.socialButtons}>
      <a href={`mailto:${info.email}`} className={classNames([styles.button, styles.emailIcon])} onClick={() => { track("email") }} target="_blank" rel="noreferrer">
        <EmailIcon />
        <div className={classNames([styles.line, styles.line1])}></div>
        <div className={classNames([styles.line, styles.line2])}></div>
        <div className={classNames([styles.line, styles.line3])}></div>
      </a>

      <a href={info.instagram} className={classNames([styles.button, styles.instaIcon])} onClick={() => { track("instagram") }} target="_blank" rel="noreferrer">
        <IntsaIcon />
        <div className={styles.pointsWrapper}>
          {[...Array(8)].map((item, index) => {
            return (
              <div key={`point-${index}`} className={styles.pointWrapper}>
                <div className={styles.point}></div>
              </div>
            )
          })}
        </div>
      </a>
    </div>
  );
}

export default InfoButton;
