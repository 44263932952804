import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/appContext";
import SocialButtons from '../SocialButtons'
import classNames from "classnames";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from './infoPage.module.scss';

function InfoButton() {
  const { movies, info, menuOpen, activeVideo, selectVideo } = useContext(AppContext);
  const container = useRef();

  useEffect(() => {
    if (menuOpen) container.current.scrollTop = -1000000;
  }, [menuOpen])

  if (!menuOpen) return null

  return (
    <div className={styles.infoPage} ref={container}>
      <div className={styles.left}>
        <div>
          <h1>{ info.title }</h1>
          {documentToReactComponents(info.about)}
        </div>
        <SocialButtons />
      </div>
      <div className={styles.right}>
        <div className={styles.videoList}>
          {movies.current.map((movie, i) => (
            <div key={`movie-${i}`} onClick={() => { selectVideo(movie.id) }} className={classNames([styles.videoItem, activeVideo === movie.id && styles.active])}>
              <h2>{movie.title}</h2>
              <span className={styles.label}>{movie.roles}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default InfoButton;
