import { gsap, Power2 } from "gsap/all";
import { useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import Vimeo from '@u-wave/react-vimeo';
import Spinner from '../Spinner';
import { AppContext } from "../../context/appContext";
import styles from './videoFloater.module.scss';
import ReactGA from "react-ga";

function VideoFloater({active, hover, aspectRatio, vimeoId, padding, cover}) {
  
  const { screenSize, activeVideo, slowMode, setSlowMode, darkMode, setDarkMode, menuOpen } = useContext(AppContext);

  const thumbScale = 0.4

  const player = useRef();
  const size = useRef();
  const pos = useRef({ x: 0, y: 0, scale: thumbScale, opacity: 1 });
  const playPos = useRef();
  const center = useRef({ x: '50%', y: '50%' });

  const animating = useRef(false)
  const isHover = useRef(false);
  const isActive = useRef(active);
  const hasBeenActive = useRef(active);

  const loading = useRef(false);
  const playing = useRef(false);
  
  const mousePos = useRef({ x: 0, y: 0 });

  const requestRef = useRef();
  const previousTimeRef = useRef();

  const onPlay = () => {
    playing.current = true
    loading.current = false
    
    setTimeout(() => {
      if (playing.current) setSlowMode(true)
    }, 2000)

    setTimeout(() => {
      if (playing.current) setDarkMode(true)
    }, 4000)

    ReactGA.event({
      category: "play video",
      action: activeVideo
    });
  }

  const onPause = () => {
    ReactGA.event({
      category: "pause video",
      action: activeVideo
    });
    onStop()
  }
  
  const onEnd = () => {
    ReactGA.event({
      category: "finish video",
      action: activeVideo
    });
    onStop()
  }

  const onStop = () => {
    playing.current = false
    setDarkMode(false)
    setSlowMode(false)
  }

  useEffect(() => {
    if (menuOpen) {
      if (player.current) player.current.player.pause()
    }
  }, [menuOpen])

  useEffect(() => {
    isHover.current = hover
  }, [hover])

  useEffect(() => {
    
    // animate in
    if (active && !isActive.current) {
      setNewPositions()

      hasBeenActive.current = true

      animating.current = true
      isActive.current = active
      gsap.fromTo(
        pos.current, 
        {
          x: pos.current.x, 
          y: pos.current.y, 
          scale: thumbScale,
          opacity: 1
        }, {
          x: playPos.current.x, 
          y: playPos.current.y, 
          scale: 1, 
          opacity: 1,
          duration: 0.5, 
          ease: Power2.easeOut,
          onComplete: () => {
            animating.current = false
            center.current = { x: '50%', y: '50%'}
            // loading.current = true
          }
        }
      );
    }

    // animate out
    if (!active && isActive.current) {
      animating.current = true

      if (slowMode) setSlowMode(false)
      if (darkMode) setDarkMode(false)

      if (player.current) player.current.player.pause()
      // player.current.player.getColor().then(e => {
      //   console.log(e)
      // })

      gsap.fromTo(
        pos.current, 
        {
          x: playPos.current.x, 
          y: playPos.current.y, 
          scale: 1, 
          opacity: 1
        }, {
          x: playPos.current.x, 
          y: playPos.current.y, 
          scale: 0, 
          opacity: 0, 
          duration: 0.5, 
          ease: Power2.easeOut,
          onComplete: () => {

            // reset all
            animating.current = false
            isActive.current = false
            isHover.current = false
            loading.current = false
            playing.current = false

            pos.current.opacity = 1
            pos.current.scale = 0.4
          }
        }
      );
    }
  }, [active]) // eslint-disable-line

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      // const deltaTime = time - previousTimeRef.current;

      if (isHover.current && !isActive.current && !animating.current) {
        const availableWidth = window.innerWidth - (padding * 6);
        const availableHeight = window.innerHeight - (padding * 6);
        
        const xP = mousePos.current.x / window.innerWidth
        const xOff = xP * availableWidth + (padding * 3) - (size.current.w * xP)
        const yP = mousePos.current.y / window.innerHeight
        const yOff = yP * availableHeight + (padding * 3) - (size.current.h * yP)

        center.current = {x: `${xP * 100}%`, y: `${yP * 100}%`}
        pos.current.x = xOff
        pos.current.y = yOff
      }

    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }

  const setNewPositions = () => {
    const availableWidth = window.innerWidth - (padding * 12);
    const availableHeight = window.innerHeight - (padding * 12);

    const availableRatio = availableHeight / availableWidth;

    let width = availableWidth;
    let height = width * aspectRatio;

    if (availableRatio < aspectRatio) {
      height = availableHeight;
      width = height / aspectRatio;
    }

    size.current = { w: width, h: height }

    const availableLeft = availableWidth - width
    const availableTop = availableHeight - height

    const left = Math.random() * availableLeft + (padding * 6)
    const top = Math.random() * availableTop + (padding * 6)

    playPos.current = { x: left, y: top }
  }

  useEffect(() => {

    window.addEventListener('mousemove', e => {
      mousePos.current = { x: e.clientX, y: e.clientY }
    })

    setNewPositions()

    if (active) {
      pos.current = { x: playPos.current.x, y: playPos.current.y, scale: 1, opacity: 1 }
      isActive.current = true
    }

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);

  }, []); // eslint-disable-line

  useEffect(() => {
    setNewPositions()

    if (active) {
      pos.current = { x: playPos.current.x, y: playPos.current.y, scale: 1, opacity: 1 }
      isActive.current = true
    }
  }, [screenSize]); // eslint-disable-line

  if (!size.current) return null

  return (
    <div 
      className={styles.videoFloater}
      style={{
        width: `${size.current.w}px`, 
        height: `${size.current.h}px`, 
        left: `${pos.current.x}px`, 
        top: `${pos.current.y}px`,
        // left: `${isActive.current ? playPos.current.x : pos.current.x}px`, 
        // top: `${isActive.current ? playPos.current.y : pos.current.y}px`,
        opacity: pos.current.opacity,
        visibility: isActive.current || isHover.current ? 'visible' : 'hidden',
        pointerEvents: isActive.current ? 'auto': 'none',
        transform: `scale(${pos.current.scale})`,
        transformOrigin: `${center.current.x} ${center.current.y}`,
        zIndex: isHover.current ? '99' : '11'
      }}>
        { hasBeenActive.current && (
          <Vimeo
            className="vimeo-player"
            style={{
              visibility: isActive.current && !animating.current ? 'visible' : 'hidden',
              pointerEvents: isActive.current && !animating.current ? 'auto' : 'none'
            }}
            ref={player}
            width={size.current.w}
            height={size.current.h}
            video={vimeoId}
            onPlay={onPlay}
            onPause={onPause}
            onEnd={onEnd}
          />
        )}

        <div
          className={classNames([styles.cover, playing.current && styles.hidden ])}
          style={{ backgroundImage: `url(${cover})` }}
        >

          {isActive.current && !animating.current && (
            <>
              <div className={classNames([styles.loading, loading.current && styles.active])}>
                <Spinner />
              </div>

              {!playing.current && !loading.current && (
                <div className={styles.playBtn} onClick={() => { loading.current = true; player.current.player.play() }}>play</div>
              )}
            </>
          )}
        </div>
    </div>
  );
}

export default VideoFloater;
