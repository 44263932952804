import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/appContext";
import classNames from "classnames";
import styles from './infobutton.module.scss';
import { ReactComponent as InfoIcon } from '../../assets/icons/info2.svg';
import { gsap, Power2 } from "gsap/all";

function InfoButton() {
  const { menuOpen, toggleMenu, darkMode } = useContext(AppContext);

  const tl = useRef();
  const tl2 = useRef();
  const icon = useRef();
  const isHover = useRef(false);

  const onOver = () => {
    isHover.current = true
    tl.current.seek(0)
    tl.current.play()
    tl2.current.seek(0)
    tl2.current.play()
  }

  const onOut = () => {
    isHover.current = false
  }

  useEffect(() => {
    tl.current = gsap.timeline();
    const dot = icon.current.querySelector('.dot');

    tl.current.to(dot, {duration: 0.4, y: -45, ease: Power2.easeOut})
    tl.current.to(dot, {duration: 0.4, y: 0, ease: Power2.easeIn, onComplete: () => {
      tl.current.seek(0)
      tl.current.pause()
      if (isHover.current) {
        tl.current.play()
      }
    }})

    tl.current.pause();
    
    tl2.current = gsap.timeline();
    const shaft = icon.current.querySelector('.shaft');

    tl2.current.to(shaft, {duration: 0.1, transformOrigin: 'center bottom', scaleY: 1, ease: Power2.easeOut})
    tl2.current.to(shaft, {duration: 0.7, transformOrigin: 'center bottom', scaleY: 1.1, ease: Power2.easeOut, onComplete: () => {
      tl2.current.seek(0)
      tl2.current.pause()
      if (isHover.current) {
        tl2.current.play()
      }
    }})

    tl2.current.pause();
    
  }, [])

  return (
    <div onMouseOver={onOver} onMouseOut={onOut} className={classNames([styles.infoButton, darkMode && styles.darkMode, menuOpen && styles.menuOpen])} onClick={toggleMenu}>
      <InfoIcon ref={icon} />
    </div>
  );
}

export default InfoButton;
